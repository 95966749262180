@import url(https://fonts.googleapis.com/css?family=Karla);
@import url(https://fonts.googleapis.com/css?family=Karla);
@import url(https://fonts.googleapis.com/css?family=Karla);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Landing {
    text-align: center
}

.Landing-header {
    background-color: #FFFAF0;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    font-family: Georgia, serif;
}

.intro {
    font-size: 500%;
    margin-bottom: 0
}

.typewriter {
    font-size: 250%
}
.About {
    text-align: center;
    background-color: #FFFAF0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.About-content {
    text-align: center;
    margin: auto;
    width: 70%;
    color: black;
    font-family: Georgia, serif;
}

.rightContent {
    float: right;
    text-align: left;
    width: 50%;
    min-width: 300px;
    font-family: Karla;
}

#profileImage {
    float: left;
    width: 40%;
    border: none;
    box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
}

#aboutTitle {
    color: #4d79cb;
    font-family: Georgia, serif;
}

.link {
    text-decoration: none;
    color: #4d79cb;
}

#socials {
    color: #4d79cb;
}
.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: rgba(255, 250, 240, 0.5);
}

.navbar ul {
    list-style: none;
    text-align: right;
}

.navItem {
    color: black;
    font-size: 1.3em;
    font-family:  Georgia, serif; 
    padding-right: 30px;
    display: inline;
}

.navItem:hover {
    color: #4d79cb;
    cursor: pointer;
}
.Projects {
    background-color: #FFFAF0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Projects-content {
    margin: auto;
    width: 70%;
    color: black;
    font-family: Georgia, serif;
}

h1 {
    color: #4d79cb;
}

.card {
    display:inline-block;
    padding-right: 5%;
    padding-top: 5%;
    font-family: Karla;
}

.cardTitle {
    font-weight: bold;
}

.cardButton {
    background-color:  rgb(170, 167, 158);
    color: #FFFAF0;
    border: none;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
}

.cardButton:hover {
    cursor: pointer;
}

.modal {
    color: #4d79cb
}
/* 
.Home-wrapper {
    text-align: center;
    background-color: #FFFAF0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
} */

.Home-wrapper {
    text-align: left;
    margin: auto;
    width: 50%;
    min-width: 268px;
    color: black;
    font-family: Georgia, serif;
}

.Home-content {
    font-family: Karla;
}

#homeTitle {
    color: #4d79cb;
    font-family: Georgia, serif;
    font-size: 50px;
}

.link {
    text-decoration: none;
    color: #4d79cb;
}

#socials {
    color: #4d79cb;
}
