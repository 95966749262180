.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: rgba(255, 250, 240, 0.5);
}

.navbar ul {
    list-style: none;
    text-align: right;
}

.navItem {
    color: black;
    font-size: 1.3em;
    font-family:  Georgia, serif; 
    padding-right: 30px;
    display: inline;
}

.navItem:hover {
    color: #4d79cb;
    cursor: pointer;
}