.Landing {
    text-align: center
}

.Landing-header {
    background-color: #FFFAF0;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    font-family: Georgia, serif;
}

.intro {
    font-size: 500%;
    margin-bottom: 0
}

.typewriter {
    font-size: 250%
}